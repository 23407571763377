import { render, staticRenderFns } from "./Roleperm.vue?vue&type=template&id=56f61ff8&scoped=true&"
import script from "./Roleperm.vue?vue&type=script&lang=js&"
export * from "./Roleperm.vue?vue&type=script&lang=js&"
import style0 from "./Roleperm.vue?vue&type=style&index=0&id=56f61ff8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f61ff8",
  null
  
)

export default component.exports